<template>
  <vuestic-widget>
    <div class="va-row d">
      <el-input
        style="margin-bottom: 10px; width: 150px;"
        v-model="filters[0].value"
        placeholder="Pesquisar"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
        ></i>
      </el-input>
      <button
        class="btn btn-primary btn-micro pull-right"
        @click.prevent="$router.push({name: 'cadastrar-relatos'})"
      > Cadastrar
      </button>
    </div>
    <data-tables
      :actionCol="actionCol"
      :data="modeloRelatos"
      :filters="filters"
      :table-props="tableProps"
      v-loading="loading"
    >
      <el-table-column
        prop="nome"
        label="Nome"
        sortable="custom"
      />
    </data-tables>
     <vuestic-modal
      ref="modalDeletar"
      v-on:ok="deletarModelo(modelo.id)"
    >
      <div slot="title">Excluir</div>
      <div>Você deseja excluir o relato <b>{{modelo.nome}}</b>?</div>
    </vuestic-modal>
  </vuestic-widget>
</template>
<script>
export default {
  name: 'cadastrar-tipo',
  data() {
    return {
      loading: false,
      modeloRelatos: [],
      modelo: {},
      tableProps: {
        stripe: true,
      },
      actionCol: {
        label: 'EDITAR/EXCLUIR',
        props: {
          align: 'center',
        },
        buttons: [
          {
            props: {
              circle: true,
              type: 'primary',
              icon: 'el-icon-edit',
            },
            handler: (row) => {
              this.$router.push({
                name: 'editar-relato',
                params: { id: row.id },
              });
            },
          },
          {
            props: {
              circle: true,
              type: 'danger',
              icon: 'el-icon-delete',
            },
            handler: (row) => {
              this.modelo = row;
              this.$refs.modalDeletar.open();
            },
          },
        ],
      },
      filters: [
        {
          value: '',
          prop: ['nome'],
        },
      ],
    };
  },
  created() {
    this.getModelos();
  },
  methods: {
    getModelos() {
      this.loading = true;
      this.$axios.get('/api/modelo/').then((res) => {
        this.loading = false;
        this.modeloRelatos = res.data;
      });
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    deletarModelo(id) {
      this.$axios.delete(`/api/modelo/${id}/`).then(() => {
        this.message('success', 'Modelo de Relato excluido!!!');
        this.getModelos();
      }).catch(() => this.message('error', 'Erro ao deletar!!! Verifique se existe(m) cadastro(s) vinculado(s) a este item.'));
    },
  },
};
</script>
